import React, { useState } from 'react';
import axapi from '../../utils/axios';
import { ToastContainer, toast } from 'react-toastify';

export const AddFeatures = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    setNameError('');
    setMessage('');
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    setDescription(newDescription);
    setDescriptionError('');
    setMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name.length < 10 || name.length > 100) {
      setNameError('Name must be between 10 and 100 characters.');
      return;
    }
    if (description.length > 200) {
      setDescriptionError('Description must not exceed 200 characters.');
      return;
    }
    try {
      const response = await axapi.post('/admin/post-feature', {
        name,
        description,
      });
      if (response.status === 201) {
        toast.success('Feature added successfully!');
        setName('');
        setDescription('');
      }
    } catch (error) {
        console.error('Error',error);
        if(error.response.status === 400){
          setMessage('Feature name must be unique.');
        }
       else {
        setMessage('Error adding feature.');
      }
      
    }
  };
  
    return (
       
      <div className=" w-[550px] max-w-lg mx-auto mt-20 p-6 bg-white shadow-md rounded-md">
      <ToastContainer position="top-center" />
      <h1 className="text-2xl font-bold mb-4">Add New Feature</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">
            Feature Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => handleNameChange(e)}
            required
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
           {nameError && <p className="text-red-500">{nameError}</p>}
       
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block text-gray-700 font-semibold mb-2">
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => handleDescriptionChange(e)}
            className="w-full h-[160px] px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          ></textarea>
          {descriptionError && <p className="text-red-500">{descriptionError}</p>}
    
        </div>
        
        <button
          type="submit"
          className="w-full bg-[#000066] text-white font-bold py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300"
        >
          Add Feature
        </button>
      </form>
      {message && <p className="text-red-500 mt-4">{message}</p>}
    </div>
  );
};

  