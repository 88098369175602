import React, { useState } from 'react';
import { Card, CardContent, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axapi from '../../utils/axios';
import { useRecoilState } from 'recoil';
import { authState } from '../../state/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import NavBar from "../misc/NavBar";

export const Login = ({ setVerifyMode }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [, setAuthData] = useRecoilState(authState);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axapi.post('/auth/login/admin', {
        email,
        password,
      });

      if (res.data.body.emailVerified) {
        const { token } = res.data.body;
        const decoded = jwtDecode(token);
        setAuthData({
          auth: true,
          token,
          user: decoded.id,
        });
        localStorage.setItem('token', token);
        navigate('/');
        return;
      }

      setVerifyMode(true);
      toast.error(res.data.message); // Display an error toast
    } catch (error) {
      const errors = error.response.data.errors;
      toast.error(errors[0]); // Display an error toast
    }
  };

  return (
    <>
     <ToastContainer position="top-center" />
      {/* <NavBar /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "93vh",
          backgroundColor: "#0037A5",
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="bg-white w-[430px] p-12 pt-5">
            <div className="text-center text-[24px] font-[700] mb-4">
              Sign In
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="email"
                className="font-[400] text-[14px] text-[#21272A]"
              >
                Email Address
              </label>
              <input
                type="text"
                id="email"
                name="email"
                required
                value={email}
                placeholder="Email"
                className="appearance-none block w-full px-3 py-2 mt-1 mb-2 border-b  shadow-sm placeholder:text-[16px] focus:border focus:outline-none focus:ring-gray-500 focus:border-gray-300 text-[14px] bg-[#F2F4F8]"
                onChange={(e) => setEmail(e.target.value)}
              />
              <label
                htmlFor="password"
                className="font-[400] text-[14px] text-[#21272A]"
              >
                Password
              </label>
              <div className=" relative ">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 mt-1 mb-2 border-b  shadow-sm placeholder:text-[16px] focus:border focus:outline-none focus:ring-gray-500 focus:border-gray-300 text-[14px] bg-[#F2F4F8]"
                  placeholder="Password"
                />

                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-3 cursor-pointer "
                    size={20}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-3 cursor-pointer"
                    size={20}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
              <button
                type="submit"
                className="cursor-pointer bg-[#0037A5] p-2 text-[#FDD023] mt-3 text-[16px]"
              >
                Sign In
              </button>
            </div>
          </div>
        </form>
       
      </div>
    </>
  );
  
};

