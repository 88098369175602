import {
   
    Container,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    CircularProgress,
    Tab,
  } from '@mui/material';
 import Features from '../modals/Features';
  
  import { useEffect, useState, useRef } from 'react';
  import { ToastContainer, toast } from 'react-toastify';
  import axapi from '../../utils/axios';
  
  export const ActivatedUsers = () => {
    const [userList, setUserList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openFeatureFlag, setOpenFeatureFlag] = useState(false);
    const [featuresList, setFeatureList] = useState([]);
    const userState = useRef({id: "", name: ""})
    const [featureStates, setFeatureStates] = useState({});
  
    useEffect(() => {
      const fetchFeaturesList = async () => {
        try{
          const res = await axapi.get('/feature-flags');
          setFeatureList(res.data);
        }catch(error)
        {
          console.error('errror fetching features list', error);
        }
      }
      fetchFeaturesList();
    },[]);

    useEffect(() => {
      const fetchUsers = async () => {
        try {
          // Fetch the user list
          const { data: users } = await axapi.get("/admin/userlist");

          // Fetch feedback counts for all users
          const feedbackResponse = await axapi.get("/chat/feedback-count");
          const feedbackData = feedbackResponse.data.counts;
          const reviewsResponse = await axapi.get("/admin/reviews-by-user");

          const reviewsByUser = reviewsResponse.data.reviewsCount.reduce(
            (acc, rev) => {
              acc[rev._id] = rev.reviewCount;
              return acc;
            },
            {}
          );

          const activatedUsersList = users.map((user) => ({
            ...user,
            feedback: feedbackData[user._id] || { likes: 0, dislikes: 0 },
            reviews: reviewsByUser[user._id] || 0,
          }));

          activatedUsersList.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setUserList(activatedUsersList);
        } catch (err) {
          toast.error("Some error occurred, please check console");
          console.error(err);
        } finally {
          setIsLoading(false); // Set loading to false regardless of the outcome
        }
      };
      fetchUsers();
    }, []);

    const handleFeatureFlags = async (userId, name) => {
      userState.current.name = name;
      userState.current.id = userId;
      const res = await axapi.get(`/admin/${userId}/feature-flags`);

      setFeatureStates(
        featuresList.reduce((acc, feat) => {
          acc[feat] = {
            checked: res.data.includes(feat),
            disabled: true,
          };
          return acc;
        }, {})
      );

      setOpenFeatureFlag(true);
    };

    const handleSend = async (_id) => {
      try {
        const res = await axapi.post("/admin/sendreleaseupdate", {
          _id,
        });
        toast.success(res.data.message);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          console.log("500");
          const errorMessage = error.response.data.error;
          console.log(error.message);

          if (
            errorMessage.includes("SMTP server error: Unable to send email")
          ) {
            toast.error(
              "SMTP server error: Unable to send email. Please try again later."
            );
          } else {
            toast.error("Some Error Occurred");
          }
        } else {
          console.error(error);
          toast.error("Some Error Occurred");
        }
      }
    };
    if (isLoading) {
      return (
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Container>
      );
    }

    return (
      <>
        <ToastContainer position="top-center" />
        <br />
        <Container>
          <Typography variant="h3">Activated Users List</Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>No. of Reviews</TableCell>
                  <TableCell>Total Files </TableCell>
                  <TableCell>Total Queries</TableCell>
                  <TableCell>Total Likes/Dislikes</TableCell>
                  <TableCell>Send Release</TableCell>
                  <TableCell>Feature Flags</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userList.map((user) => (
                  <TableRow key={user.name + Math.random() * 10}>
                    <TableCell component="th" scope="row">
                      {user.name}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.reviews}</TableCell>
                    <TableCell>{user.uploadCount}</TableCell>
                    <TableCell>{user.queryCount}</TableCell>
                    <TableCell>
                      {user.feedback.likes} / {user.feedback.dislikes}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleSend(user._id)}
                        disabled={user.isSubscribed === false}
                      >
                        Send Release
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleFeatureFlags(user._id, user.name)}
                        disabled={user.isSubscribed === false}
                      >
                        Feature Flags
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
        <Features
          openFeatureFlag={openFeatureFlag}
          setOpenFeatureFlag={setOpenFeatureFlag}
          userState={userState.current}
          featureStates={featureStates}
          featuresList={featuresList}
          setFeatureStates={setFeatureStates}
        />
      </>
    );
  };
  