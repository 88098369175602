import axios from "axios";

// export const baseURL = 'http://localhost:3000/api/v1';
// const baseURL = 'https://veritus-server.sharath.uk/api/v1';
export const baseURL = "https://server.veritus.ai/api/v1";
// export const baseURL = "https://dev.veritus.ai/api/v1";
const axapi = axios.create({
  baseURL,
  withCredentials: true,
});

axapi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers["authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axapi;
