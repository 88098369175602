import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ProgressBar from "./ProgressBar";
import MenuIcon from "@mui/icons-material/Menu";
import { useRecoilState, useRecoilValue } from "recoil";
import { authSelector, authState } from "../../state/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useLogout from "./logout";

export default function NavBar() {
  const auth = useRecoilValue(authSelector);
  const [, setAuthData] = useRecoilState(authState);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const logout = useLogout();

  const handleLogout = () => {
    logout();
  };
  const handleSelectWaitlist = () => {
    navigate("/waitlist");
    setDrawerOpen(false);
  };
  const handleSelectPendingList = () => {
    navigate("/pendinguserslist");
    setDrawerOpen(false);
  };
  const handleSelectUserList = () => {
    navigate("/userslist");
    setDrawerOpen(false);
  };
  const handleSelectAddFeature = () => {
    navigate("/addfeatures");
    setDrawerOpen(false);
  };
  const handleBenchmarkList = () => {
    navigate("/benchmark");
    setDrawerOpen(false);
  };
  const handleCreditSelect = () => {
    navigate("/credits-setting");
    setDrawerOpen(false);
  };
  return (
    <AppBar style={{ position: "inherit", backgroundColor: "rgb(0, 55, 165)" }}>
      <ProgressBar />
      <Toolbar>
        <div className="flex items-end justify-between w-full">
          <div className="flex items-center">
            {auth && (
              <IconButton
                sx={{ color: "white" }}
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" className="pl-5">
              ADMIN PANEL
            </Typography>
          </div>

          <div>
            {auth && (
              <Button
                variant="outlined"
                color="info"
                style={{ color: "white" }}
                onClick={handleLogout}
              >
                LOGOUT
              </Button>
            )}
          </div>
        </div>
      </Toolbar>
      <Drawer
        anchor={"left"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box sx={{ width: 250 }} role="presentation">
          <List>
            <div style={{ height: "75px" }}></div>
            <ListItem disablePadding>
              <ListItemButton onClick={handleSelectWaitlist}>
                <ListItemIcon>
                  <HourglassEmptyIcon />
                </ListItemIcon>
                <ListItemText primary="Waitlist" />
              </ListItemButton>
            </ListItem>
            {/* <div style={{ height: '75px' }}></div> */}
            <ListItem disablePadding>
              <ListItemButton onClick={handleSelectPendingList}>
                <ListItemIcon>
                  <HourglassEmptyIcon />
                </ListItemIcon>
                <ListItemText primary="Pending Users List" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleSelectUserList}>
                <ListItemIcon>
                  <HourglassEmptyIcon />
                </ListItemIcon>
                <ListItemText primary="Activated Users List" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleSelectAddFeature}>
                <ListItemIcon>
                  <HourglassEmptyIcon />
                </ListItemIcon>
                <ListItemText primary="Add New Feature" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleCreditSelect}>
                <ListItemIcon>
                  <HourglassEmptyIcon />
                </ListItemIcon>
                <ListItemText primary="Credit Settings" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleBenchmarkList}>
                <ListItemIcon>
                  <HourglassEmptyIcon />
                </ListItemIcon>
                <ListItemText primary="Benchmark" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
}
